export const environment = {
  production: true,
  // Production
  base: "https://bidatrade.com",
  apiUrl: "https://bidatrade.com/server/api/",

  // Local
  //base: "https://localhost:5001/",
  //apiUrl: "http://localhost:5001/api/",

  // Dev
  //apiUrl: 'https://bidatrade.azurewebsites.net/api',
  //base: 'https://bidatrade.azurewebsites.net/'
};
