import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResetPasswordModel } from 'src/app/admin-portal/models/resetpassword-model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private _http: HttpClient) { }
  token = localStorage.getItem('auth_token');

  // http interceptor
  httpOptions() {
   const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token
     })
   };
   return httpOptions;
 }

   // send reset password link
   public sendResetPasswordLink(resetPasswordModel: ResetPasswordModel): Observable<ResetPasswordModel> {
    return this._http.post<ResetPasswordModel>(`${environment.apiUrl}/Account/ForgotPassword`, resetPasswordModel, this.httpOptions());
  }

}
