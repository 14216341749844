import { Component, OnInit } from '@angular/core';
import { AdminLoginService } from 'src/app/admin-portal/services/admin-login/admin-login.service';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  constructor(private adminLoginService: AdminLoginService, private router: Router) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkToken();
      };
    });
  }

  show: boolean = false;

  ngOnInit() {
    this.checkToken();
  }

  //check token to change the header
  checkToken(){
    var token = localStorage.getItem('auth_token');
    if(token != null){
      this.show = true;
    }
    else{
      this.show = false;
    }
  }

  //logout from the application
  logout(){
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#72BF44',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.value) {
        this.adminLoginService.logoutUser();

      }
    });
  }


}
