import { Component, OnInit } from "@angular/core";
import { AdminLoginService } from "src/app/admin-portal/services/admin-login/admin-login.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup
} from "@angular/forms";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ResetPasswordModel } from "src/app/admin-portal/models/resetpassword-model";
import { UserService } from "src/app/admin-portal/services/user.service";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();

  constructor(
    private sharedService: SharedService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    // login form group
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  // send reset password link to the user's email
  onSubmit(emailValue) {
    // console.log(emailValue.email);
    //for loader
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 2000);

    this.resetPasswordModel.email = emailValue.email;
    this.sharedService
      .sendResetPasswordLink(this.resetPasswordModel)
      .subscribe((data: any) => {
        // console.log(data);
        if (data.result == true) {
          Swal.fire({
            position: "center",
            type: "success",
            title: "The link has been sent!",
            showConfirmButton: true,
          });

        } else {
         Swal.fire({
          position: "center",
          type: "error",
          title: data.message,
          showConfirmButton: true,
         });
        }
      });
  }
}
