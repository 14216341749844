import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminLoginService {

  private auth_token: string;
  private loggedIn = false;
  UserName = '';
  errormessage = '';
  baseUrl = environment.apiUrl;

  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();

  constructor(
        private httpClient: HttpClient,
        private http: Http,
        private router: Router
  ) {
        this.loggedIn = !!localStorage.getItem('auth_token');
        this._authNavStatusSource.next(this.loggedIn);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
    } else {
        console.error(
            `Backend returned code ${error.error.ModelState[0]}, ` +
                `body was: ${error.error}`
        );
    }
    return throwError('Something bad happened; please try again later.');
}

protected getCommonOptions() {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return httpOptions;
}

login(entity): Observable<any> {
  const url = this.baseUrl + '/Account/LoginWeb';
  return this.httpClient.post<any>(url, entity, this.getCommonOptions());
}

authenticate(value) {
  this.loggedIn = value;
}

logoutUser() {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('auth_role');
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
  this.loggedIn = false;
  this.auth_token = '';
  this._authNavStatusSource.next(false);
  if (!this.loggedIn) {
      this.router.navigateByUrl('/admin/login');
  }
}

checkLoggedIn() {
  const localStorageItem = localStorage.getItem('auth_token');
  if (localStorageItem !== null) {
      if (localStorage.auth_role === 'SuperAdmin') {
          this.router.navigateByUrl('/admin/dashboard');
      } else if (localStorage.auth_role === 'Admin') {
          this.router.navigateByUrl('/admin/dashboard');
      } else if (localStorage.auth_role === 'Normal') {
          this.router.navigateByUrl('/admin/dashboard');
      } else {
          this.router.navigateByUrl('/admin/login');
      }
  }
}

setAuthToken(value) {
  this.auth_token = value;
}

isLoggedIn() {
  const token = localStorage.getItem('auth_token');
  if (token) {
      return true;
  }
      return false;
  }

forgotPassword(email: string) {
  return this.http.post('', email);
}

}
