import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './shared/components/admin/admin.component';
import { MarketingComponent } from './shared/components/marketing/marketing.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { BlankComponent } from './shared/components/blank/blank.component';

const mainRoutes: Routes = [
  {
    path: '',
    component: MarketingComponent,
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {
        path: '',
        loadChildren: './marketing-site/modules/marketing-site.module#MarketingSiteModule',
      },
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {path: 'admin', redirectTo: 'admin/login', pathMatch: 'full'},
      {path: 'admin', loadChildren: './admin-portal/modules/admin-portal.module#AdminPortalModule'},
    ]
  },
{
  path: '',
  component: BlankComponent,
  children: [
    { path: 'forgotpassword', component: ForgotPasswordComponent },
  ]
},
{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(mainRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
