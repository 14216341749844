import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {SharedModule} from './shared/modules/shared.module';
import { AdminComponent } from './shared/components/admin/admin.component';
import { MarketingComponent } from './shared/components/marketing/marketing.component';
import { AdminHeaderComponent } from './shared/components/admin-header/admin-header.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { BlankComponent } from './shared/components/blank/blank.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { ToastrModule } from 'ngx-toastr';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from  'ngx-ui-loader';
import { SharedService } from './shared/services/shared.service';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#72BF44',
  bgsColor: '#72BF44',
  pbColor: '#72BF44',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    MarketingComponent,
    AdminHeaderComponent,
    FooterComponent,
    HeaderComponent,
    BlankComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    HttpModule,
    SharedModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    AngularFontAwesomeModule,
    ReactiveFormsModule,
    CountdownTimerModule.forRoot(),
    SlickCarouselModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
  }),
  NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),

  ],
  providers: [SharedService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
