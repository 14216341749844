import { Component, OnInit } from '@angular/core';
import { Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  transparent = true;

  constructor(@Inject(DOCUMENT) private doc: Document) { }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    //let num = this.doc.documentElement.scrollTop;
    const num = Math.max(window.pageYOffset, this.doc.documentElement.scrollTop, document.body.scrollTop)
    if (num > 350) {
      this.transparent = false;
    } else if (!this.transparent) {
      this.transparent = true;
    }
  }
}
